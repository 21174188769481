<template>
	<div id="app" class="container">
		<div class="first-section">
			<img src="/static/images/bg.png" class="bg-image" />
			<div class="qiang_first" id="qiang_first" @click="handleQiang"></div>
			<div class="head-container">
				<div class="faq-button">
					<a :href="kf"><img src="/static/images/faq.png" class="faq-img" id="contact_faq" /></a>
				</div>
				<div class="account-box">
					<div class="account">账号</div>
					<div class="account-number">
						<input type="text" placeholder="请输入您的媚姬appid" maxlength="15" v-on:blur="handleBlur" v-model="appid">
					</div>
				</div>
                <div v-if="!hbenable || target_date == ''" id="changeHtml" class="activity-over"><span>抱歉，红包活动已结束！</span></div>
				<div id="changeHtml" v-else>
					<div class="countdown-box">
                        <span v-if="hbruning">距离红包雨结束倒计时：</span>
                        <span v-else>距离红包雨开始倒计时：</span>
						<ul class="countdown">
							<li>
								<span class="days">{{ dateData.days }}</span>
								<p class="days_ref">天</p>
							</li>
							<li class="seperator">:</li>
							<li>
								<span class="hours">{{ dateData.hours }}</span>
								<p class="hours_ref">时</p>
							</li>
							<li class="seperator">:</li>
							<li>
								<span class="minutes">{{ dateData.minutes }}</span>
								<p class="minutes_ref">分</p>
							</li>
							<li class="seperator">:</li>
							<li>
								<span class="seconds">{{ dateData.seconds }}</span>
								<p class="seconds_ref">秒</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="rest-container">
			<div class="second-section">
				<div class="qiang" id="qiang" @click="handleQiang"></div>
				<!--<div class="second-text">北京时间<span>12月25日至12月25日</span>，<br>每天<span>09:00-22:00</span>限时开抢！</div>-->
				<div class="second-text">北京时间<span>每周日</span>，
					<br>每天<span>四次</span>限时开抢！</div>
			</div>
			<div class="third-section"></div>
			<table class="table-style personal-award" id="hb">
                <thead>
                    <tr><td>抢红包时间</td><td>appid</td><td>金额</td><td>状态</td></tr>
                </thead>
				<tbody v-if="hbdata.length > 0">
                    <tr v-for="item,index in hbdata" :key="index"><td>{{ item.updatetimeStr }}</td><td>{{ item.appid }}</td><td>{{ item.money }}元</td><td>{{ item.status==2 ? "已派送":"派送中" }}</td></tr>
				</tbody>
			</table>
			<div class="table-food"></div>
			<div class="four-section"></div>
			<br>
		</div>
        <loading :active.sync="isLoading" 
            :can-cancel="true" 
            :is-full-page="fullPage">
        </loading>
	</div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: 'App',
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            fullPage: true,
            kf: String,
            hbenable: false, // 红包活动是否开启
            isHbRequest: false, // 红包按钮是否可点击
            hbruning: false, // 红包雨是否进行中
            hbdata: Array,
            appid: '',
            dateData: {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            },
            target_date: String,
            time_offset: +8,
            interval: 0, // 定时器
        }
    },
    methods: {
        // 获取个人红包记录
        getReward () {
            var _this = this
            axios.get('/api/rewardLog')
            .then(response => {
                if(response.data.code == 0) {
                    _this.hbdata = JSON.parse(response.data.dataList) == null ? '' : JSON.parse(response.data.dataList)
                }
            })
        },
        // cookies操作
        // 设置cookies
        setCookie(name, value) {
            var Days = 5;
            var exp = new Date();
            exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
            document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
        },
        //读取cookies 
        getCookie(name) {
            var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
            arr = document.cookie.match(reg)
            if (arr)  {
                return arr[2]=="undefined" ? '' : unescape(arr[2]);
            } else {
                return '';
            }
        },
        //删除cookies 
        delCookie(name) {
            var exp = new Date();
            exp.setTime(exp.getTime() - 1);
            var cval = this.getCookie(name);
            if (cval != null) document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
        },
        handleBlur() {
            this.appid = this.appid.replace(/\s/g, '') // 删除空字符
            if(!this.appid.match(/^\d+$/)) {
                alert('您的appid格式不正确，请打开媚姬直播app查看或询问客服')
                return false
            }
            this.delCookie('appid')
            this.setCookie('appid', this.appid)
        },
        handleQiang() {
            if(!this.isHbRequest) {
                return false
            }
            if(!this.hbenable || !this.hbruning) {
                alert('红包活动尚未开始')
                return false
            }
            if(this.appid == '') {
                alert('请先输入您的appid')
                return false
            }
            this.isLoading = true;
            this.isHbRequest = false // 先禁用抢红包按钮，等待返回结果
            var _this = this
            axios.post('/api/red')
            .then(response => {
                if(response.data.code == 0) {
                    alert(response.data.msg)
                    _this.isHbRequest = true  // 启用抢红包按钮
                    this.getReward()
                    this.isLoading = false
                } else {
                    alert(response.data.msg)
                    _this.isHbRequest = true  // 启用抢红包按钮
                    this.isLoading = false
                }
            })
        },
        countdown () {
            var target_date = this.beijingDate(target_date)
            var difference = new Date(this.target_date) - this.beijingDate();
            if (difference < 0) {
                clearInterval(this.interval);
                location.reload();
                return;
            }
            var _second = 1000,
                _minute = _second * 60,
                _hour = _minute * 60,
                _day = _hour * 24;
            var days = Math.floor(difference / _day),
                hours = Math.floor((difference % _day) / _hour),
                minutes = Math.floor((difference % _hour) / _minute),
                seconds = Math.floor((difference % _minute) / _second);
                this.dateData.days = (String(days).length >= 2) ? days : '0' + days;
                this.dateData.hours = (String(hours).length >= 2) ? hours : '0' + hours;
                this.dateData.minutes = (String(minutes).length >= 2) ? minutes : '0' + minutes;
                this.dateData.seconds = (String(seconds).length >= 2) ? seconds : '0' + seconds;
                // console.log(this.dateData.seconds)
        },
        beijingDate (timeStr) { // 当前北京时间, 如果给定如 '01/04/2023 18:00:00' 格式的时间字符串，则转换该字符串为对应北京时间对象
            var date
            if (timeStr) {
                date = new Date(timeStr);
            } else {
                date = new Date();
            }
            var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
            var new_date = new Date(utc + (3600000*this.time_offset))
            // console.log(new_date.getMinutes())
            return new_date;
        }
    },
    mounted () {
        this.getReward()
        this.appid = this.getCookie('appid')
        // 获取配置
        var _this = this
        axios.get('/api/config')
        .then(response => {
            if(response.data.code == 0) {
                // hbenable: false, // 红包活动是否开启
                // isHbRequest: false, // 红包按钮是否可点击
                _this.hbenable = response.data.hbenable == 1 ? true : false
                _this.target_date = response.data.target_date
                _this.hbruning = response.data.hbruning == 1 ? true : false
                _this.kf = response.data.kf
                if(_this.hbenable && _this.target_date != "") {
                    _this.interval = setInterval(_this.countdown, 1000);
                }
                _this.isHbRequest = true
            } else {
                alert(response.data.msg)
            }
        })
    },
}
</script>
<style scoped>
#hb thead{color: #e9ad48;font-weight: bold;text-align: left;}
.countdown-box > span{font-size: 0.8em;color:#fff;}
</style>